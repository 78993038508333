import { PURGE } from "redux-persist";

var initialState = {
  privacyPolicyResponse: null,
  termAndConditionModal: false,
};

const PrivacyPolicy = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "SET_PRIVACY_POLICY_RESPONSE":
      return {
        ...state,
        privacyPolicyResponse: payload,
      };

    case "SET_TERM_AND_CONDITION_MODAL":
      return {
        ...state,
        termAndConditionModal: payload,
      };

    case "RESET_STORE":
      return (state = initialState);

    default:
      return state;
  }
};

export default PrivacyPolicy;
