export const setCurrentPage = data => ({
  type: "SET_CURRENT_PAGE",
  payload: data,
});

export const setOrderObject = data => ({
  type: "SET_ORDER_OBJECT",
  payload: data,
});

export const setInstructions = data => ({
  type: "SET_INSTRUCTIONS",
  payload: data,
});

export const setDoOverCount = data => ({
  type: "SET_DO_OOVER_COUNT",
  payload: data,
});

export const setStarch = data => ({
  type: "SET_STARCH",
  payload: data,
});

export const setCrease = data => ({
  type: "SET_CREASE",
  payload: data,
});

export const setBlanket = data => ({
  type: "SET_BLANKET",
  payload: data,
});

export const setComforter = data => ({
  type: "SET_COMFORTER",
  payload: data,
});

export const setAmount = data => ({
  type: "SET_AMOUNT",
  payload: data,
});

export const setTermAndCondition = data => ({
  type: "SET_TERMS_AND_CONDITIONS",
  payload: data,
});

export const setTableCloth = data => ({
  type: "SET_TABLECLOTH",
  payload: data,
});

export const setNumberOfItems = data => ({
  type: "SET_NUMBER_OF_ITEMS",
  payload: data,
});

export const setTipIn = data => ({
  type: "SET_TIP_IN",
  payload: data,
});

export const setOrderResponse = data => ({
  type: "SET_ORDER_RESPONSE",
  payload: data,
});

export const setCustomerOrdersResponse = data => ({
  type: "SET_CUSTOMER_ORDERS_RESPONSE",
  payload: data,
});

export const setSortKey = data => ({
  type: "SET_SORT_KEY",
  payload: data,
});

export const setSearch = data => ({
  type: "SET_SEARCH",
  payload: data,
});

export const setSortClass = data => ({
  type: "SET_SORT_CLASS",
  payload: data,
});

export const setOrderSummary = data => ({
  type: "SET_ORDER_SUMMARY",
  payload: data,
});

export const setCancelOrderModal = data => ({
  type: "SET_CANCEL_ORDER_MODAL",
  payload: data,
});

export const setCancelOrder = data => ({
  type: "SET_CANCEL_ORDER",
  payload: data,
});

export const setCancelOrderResponse = data => ({
  type: "SET_CANCEL_ORDER_RESPONSE",
  payload: data,
});

export const setTipDetailsResponse = data => ({
  type: "SET_TIP_DETAILS_RESPONSE",
  payload: data,
});

export const setPriceListing = data => ({
  type: "SET_PRICES",
  payload: data,
});

export const setTipValue = data => ({
  type: "SET_TIP_VALUE",
  payload: data,
});

export const setOtherTipValue = data => ({
  type: "SET_OTHER_TIP_VALUE",
  payload: data,
});

export const setLocation = data => ({
  type: "SET_LOCATION",
  payload: data,
});
// const rootURL = "https://pndapi.drycleandepotdelivers.com/";
export const postWebOrders = (viewModel, token) => {
  return async function (dispatch) {
    return await postData(`${process.env.REACT_APP_ROOT_URL}/Order/PostWebOrder`, viewModel, token).then(data => {
      dispatch(setOrderResponse(data));
    });
  };
};

export const postSearchCustomerOrders = (viewModel, token) => {
  return async function (dispatch) {
    return await postData(`${process.env.REACT_APP_ROOT_URL}/Order/PostSearchCustomerWebOrders`, viewModel, token)
      .then(data => {
        dispatch(setCustomerOrdersResponse(data));
      })
      .catch(err => console.log(err));
  };
};

export const postSearchedCustomerOrders = (viewModel, token) => {
  return async function (dispatch) {
    return await postData(`${process.env.REACT_APP_ROOT_URL}/Order/PostSearchedCustomerOrders`, viewModel, token).then(data => {
      dispatch(setCustomerOrdersResponse(data));
    });
  };
};

export const fetchOrderDetails = (customerId, token) => {
  return async function (dispatch) {
    return await fetch(`${process.env.REACT_APP_ROOT_URL}/Order?custId=${customerId}&orderId=${0}`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        Authorization: token,
        "Content-Type": "application/json, charset=utf-8",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setOrderObject(result));
        },
        error => {
          console.error(error);
        }
      );
  };
};

export const getCancelOrder = (orderId, token) => {
  return async function (dispatch) {
    return await fetch(`${process.env.REACT_APP_ROOT_URL}/Order/GetCancelOrder?cancelSource=Customer&orderNo=${orderId}&orderOperation=Cancel`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setCancelOrderResponse(result));
        },
        error => {
          console.error(error);
        }
      );
  };
};

export const fetchOrderSummary = (customerId, token, orderNo) => {
  return async function (dispatch) {
    return await fetch(`${process.env.REACT_APP_ROOT_URL}/Order?custId=${customerId}&orderId=${orderNo}`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setOrderSummary(result));
        },
        error => {
          console.error(error);
        }
      );
  };
};

export const fetchTipDetails = token => {
  return async function (dispatch) {
    return await fetch(`${process.env.REACT_APP_ROOT_URL}/TippingInfo/GetTippingInfo`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        Authorization: token,
        "Content-Type": "application/json, charset=utf-8",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setTipDetailsResponse(result));
        },
        error => {
          console.error(error);
        }
      );
  };
};

export const getPriceListing = () => {
  return async function (dispatch) {
    return await fetch(`${process.env.REACT_APP_ROOT_URL}/ContentManagement/GetPriceListing`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
       // Authorization: token,
        "Content-Type": "application/json, charset=utf-8",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setPriceListing(result?.priceListing));
        },
        error => {
          console.error(error);
        }
      );
  };
};

const postData = async (url = "", data = {}, token) => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};
