import { PURGE } from "redux-persist";

var initialState = {
  addressObject: null,
  zipcodeResponse: null,
  cityState: null,
  addAddressResponse: null,
  defaultAddressId: null,
  defaultAddressresponse: null,
  addressResponse: null,
  editAddressObject: null,
  editFlag: false,
  editAddressResponse: null,
  deleteAddressObject: null,
  deleteAddressResponse: null,
  latLngOfAddress:null,
};

const Address = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case "SET_ADDRESS_OBJECT":
      return {
        ...state,
        addressObject: payload,
      };

    case "SET_EDIT_ADDRESS_OBJECT":
      return {
        ...state,
        editAddressObject: payload,
      };

    case "SET_DELETE_ADDRESS_OBJECT":
      return {
        ...state,
        deleteAddressObject: payload,
      };

    case "SET_ADDRESS_RESPONSE":
      return {
        ...state,
        addressResponse: payload,
      };

    case "SET_LAT_LNG_OF_ADDRESS":
      return {
        ...state,
        latLngOfAddress: payload,
      };

    case "SET_DELETE_ADDRESS_RESPONSE":
      return {
        ...state,
        deleteAddressResponse: payload,
      };

    case "SET_DEFAULT_ADDRESS_RESPONSE":
      return {
        ...state,
        defaultAddressresponse: payload,
      };

    case "SET_EDIT_ADDRESS_RESPONSE":
      return {
        ...state,
        editAddressResponse: payload,
      };

    case "SET_DEFAULT_ADDRESS_ID":
      return {
        ...state,
        defaultAddressId: payload,
      };
    case "SET_ADD_ADDRESS_RESPONSE":
      return {
        ...state,
        addAddressResponse: payload,
      };
    case "SET_ZIP_CODE_RESPONSE":
      return {
        ...state,
        zipcodeResponse: payload,
      };

    case "SET_CITY_STATE":
      return {
        ...state,
        cityState: payload,
      };
    case "RESET_STORE":
      return (state = initialState);
    default:
      return state;
  }
};

export default Address;
