import React, { Component } from "react";
import history from "../history";

class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className='wrapper clearfix'>
          <p> Copyright © 2016-2019, Dryclean Depot v1.0.3181008, All Rights Reserved.</p>
          <ul>
            <li style={{cursor:'pointer'}}>
              <a onClick={() => history.push("/Cookies")}>Cookies</a>
            </li>
            <li style={{cursor:'pointer'}}>
              <a onClick={() => history.push("/PrivacyPolicy")}>Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
