import { PURGE } from "redux-persist";

var initialState = {
  forgetPasswordModal: false,
  forgetPasswordResponse: null,
  validateForgotKeyResponse: null,
  forgotPasswordResetResponse: null,
};

const ForgetPassword = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "SET_FORGET_PASSWORD_MODAL":
      return {
        ...state,
        forgetPasswordModal: payload,
      };

    case "RESET_STORE":
      return (state = initialState);

    case "SET_FORGET_PASSWORD_RESPONSE":
      return {
        ...state,
        forgetPasswordResponse: payload,
      };

    case "SET_VALIDATE_FORGOT_KEY_RESPONSE":
      return {
        ...state,
        validateForgotKeyResponse: payload,
      };

    case "SET_FORGOT_PASSWORD_RESET_RESPONSE":
      return {
        ...state,
        forgotPasswordResetResponse: payload,
      };

    default:
      return state;
  }
};

export default ForgetPassword;
