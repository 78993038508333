import { PURGE } from "redux-persist";

var initialState = {
  currentPage: 1,
  orderObject: null,
  doOverCount: 0,
  starch: -1,
  crease: -1,
  blanket: -1,
  comforter: -1,
  tableCloth: -1,
  instructions: "",
  noOfItems: -1,
  amount: 0.0,
  termAndCondition: false,
  orderResponse: null,
  customerOrders: null,
  sortKey: null,
  search: "",
  reverse: false,
  sortClass: null,
  orderSummary: null,
  cancelOrderModal: false,
  cancelOrder: null,
  cancelOrderResponse: null,
  tipIn: null,
  tipDetailsResponse: null,
  priceListing: null,
  otherTipValue: null,
  location: null,
  priceNoOfItems: null,
  priceBlankets: null,
  priceComforters: null,
  priceTableClothes: null
};

const Order = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: payload,
      };

    case "SET_ORDER_SUMMARY":
      return {
        ...state,
        orderSummary: payload,
      };
    case "SET_OTHER_TIP_VALUE":
      return {
        ...state,
        otherTipValue: payload,
      };

    case "SET_CUSTOMER_ORDERS_RESPONSE":
      return {
        ...state,
        customerOrders: payload,
      };

    case "SET_CANCEL_ORDER_MODAL":
      return {
        ...state,
        cancelOrderModal: payload,
      };

    case "SET_CANCEL_ORDER":
      return {
        ...state,
        cancelOrder: payload,
      };

    case "SET_CANCEL_ORDER_RESPONSE":
      return {
        ...state,
        cancelOrderResponse: payload,
      };

    case "SET_TERMS_AND_CONDITIONS":
      return {
        ...state,
        termAndCondition: payload,
      };

    case "SET_SORT_KEY":
      return {
        ...state,
        sortKey: payload,
      };

    case "SET_BLANKET":
      return {
        ...state,
        blanket: payload,
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: payload,
      };

    case "SET_ORDER_RESPONSE":
      return {
        ...state,
        orderResponse: payload,
      };

    case "SET_SORT_CLASS":
      state.reverse = !state.reverse;
      if (state.reverse)
        return {
          ...state,
          sortClass: "fa fa-caret-down pull-right",
        };
      if (!state.reverse)
        return {
          ...state,
          sortClass: "fa fa-caret-up pull-right",
        };

    case "SET_COMFORTER":
      return {
        ...state,
        comforter: payload,
      };

    case "SET_TABLECLOTH":
      return {
        ...state,
        tableCloth: payload,
      };

    case "SET_TIP_DETAILS_RESPONSE":
      return {
        ...state,
        tipDetailsResponse: payload,
      };

    case "SET_PRICES":
      var newVar;
      return {
        ...state,
        priceListing: payload,
        priceNoOfItems: payload.map((item, index) => {
          if (item.ID === 1) {
            //newArray.
            return parseFloat(item?.Price.split('$')[1]);
          }
        }),
        priceBlankets: payload.map((item, index) => {
          if (item.ID === 3) {
            //newArray.
            return parseFloat(item?.Price.split('$')[1]);
          }
        }),
        priceComforters: payload.map((item, index) => {
          if (item.ID === 4) {
            //newArray.
            return parseFloat(item?.Price.split('$')[1]);
          }
        }),
        priceTableClothes: payload.map((item, index) => {
          if (item.ID === 5) {
            //newArray.
            return parseFloat(item?.Price.split("-")[1].split('$')[1]);
          }
        }),
      };

    case "SET_ORDER_OBJECT":
      return {
        ...state,
        orderObject: payload,
      };

    case "SET_DO_OOVER_COUNT":
      return {
        ...state,
        doOverCount: payload,
      };

    case "SET_STARCH":
      return {
        ...state,
        starch: payload,
      };

    case "SET_CREASE":
      return {
        ...state,
        crease: payload,
      };
    case "SET_NUMBER_OF_ITEMS":
      return {
        ...state,
        noOfItems: payload,
      };

    case "SET_INSTRUCTIONS":
      return {
        ...state,
        instructions: payload,
      };

    case "SET_AMOUNT":
      return {
        ...state,
        amount: payload,
      };

    case "SET_TIP_VALUE":
      return {
        ...state,
        tipIn: payload,
      };

    case "SET_LOCATION":
      return {
        ...state,
        location: payload,
      };

    default:
      return state;
  }
};

export default Order;
