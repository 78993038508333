import { PURGE } from "redux-persist";

var initialState = {
  cookiesResponse: null,
};

const Cookies = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "SET_COOKIES_RESPONSE":
      return {
        ...state,
        cookiesResponse: payload,
      };

    case "RESET_STORE":
      return (state = initialState);

    default:
      return state;
  }
};

export default Cookies;
