import { combineReducers } from "redux";
import Login from "./Login.js";
import Order from "./Order";
import Common from "./Common";
import Address from "./Address";
import Card from "./Card";
import Profile from "./Profile";
import ServiceAreaAvailability from "./ServiceAreaAvailability";
import Signup from "./Signup";
import ForgetPassword from "./ForgetPassword.js";
import Promotions from "./Promotions.js";
import Cookies from "./Cookies";
import PrivacyPolicy from "./PrivacyPolicy";
import Location from './Location';
import FAQ from "./FAQ.js";

export default combineReducers({
  Login: Login,
  Order: Order,
  Common: Common,
  Address: Address,
  Card: Card,
  Profile: Profile,
  ServiceAreaAvailability: ServiceAreaAvailability,
  Signup: Signup,
  ForgetPassword: ForgetPassword,
  Promotions: Promotions,
  Cookies: Cookies,
  PrivacyPolicy: PrivacyPolicy,
  Location:Location,
  FAQ:FAQ
});
