import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import imager from "../imgs/menu_before.png";
const customStyles = {
  backgroundImage: `url(${imager})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center top",
  color: "#ca1511",
};

class Topnav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      flag_2: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    // this.setState({ flag: window.innerWidth >= 767 });

    if (window.innerWidth >= 767) {
      this.setState({ flag: true });
    }
    if (window.innerWidth < 767) {
      // if (this.state.flag_2) {
      //   this.setState({ flag: false, flag_2: false });
      // }
      this.setState({ flag: true });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  menu_check() {}

  render() {
    return (
      <div className='row main_header'>
        <div className='wrapper clearfix'>
          <a className='dryclean_logo'>
            <img src='images/dryclean_New_AllPages_logo_v4.png' alt='' />
          </a>

          <div className='menu_wrap'>
            <a onClick={() => this.setState({ flag: !this.state.flag })} className='menu_btn' style={{ cursor: "pointer" }}></a>
            {this.state.flag && (
              <div className='menu_section'>
                <ul className='clearfix'>
                  <li>
                    <NavLink activeClassName='active' exact to='/OrderHistory' activeStyle={customStyles}>
                      Order History
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' exact to='/Order' activeStyle={customStyles}>
                      Place Order
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' exact to='/Profile' activeStyle={customStyles}>
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' exact to='/ContactUs' activeStyle={customStyles}>
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' exact to='/PromotionHistory' activeStyle={customStyles}>
                      Promotions History
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' exact to='/FAQ' activeStyle={customStyles}>
                      FAQs
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Topnav;
