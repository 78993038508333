import { PURGE } from "redux-persist";

var initialState = {
  signupModal: false,
  signupModal2: false,
  signupObjectResponse: null,
  signupResponse: null,
};

const Signup = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_SIGNUP_MODAL":
      return {
        ...state,
        signupModal: payload,
      };

    case "SET_SIGNUP_MODAL2":
      return {
        ...state,
        signupModal2: payload,
      };

    case "SET_SIGNUP_OBJECT":
      return {
        ...state,
        signupObjectResponse: payload,
      };

    case "SET_POST_SIGNUP_RESPONSE":
      return {
        ...state,
        signupResponse: payload,
      };

    default:
      return state;
  }
};

export default Signup;
