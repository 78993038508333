import { PURGE } from "redux-persist";

var initialState = {
  isLoading: false,
  addAddressModal: false,
  editAddressModal: false,
  deleteAddressModal: false,
  contactUs: null,
  contactUsObject: null,
};

const Common = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_LOADER":
      return {
        ...state,
        isLoading: payload,
      };

    case "SET_ADD_ADDRESS_MODAL":
      return {
        ...state,
        addAddressModal: payload,
      };

    case "SET_CONTACT_US_RESPONSE":
      return {
        ...state,
        contactUs: payload,
      };

    case "SET_CONTACT_US_OBJECT":
      return {
        ...state,
        contactUsObject: payload,
      };

    case "SET_DELETE_ADDRESS_MODAL":
      return {
        ...state,
        deleteAddressModal: payload,
      };

    case "SET_EDIT_ADDRESS_MODAL":
      return {
        ...state,
        editAddressModal: payload,
      };

    default:
      return state;
  }
};

export default Common;
