import { PURGE } from "redux-persist";

var initialState = {
  addCardModal: false,
  cardObject: null,
  expMonthList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
  expYearList: [],
  defaultCardResponse: null,
  deleteCardResponse: null,
  selectedAddress: null,
  addCardResponse: null,
  zipCode: null,
  city: null,
  state: null,
  address1: null,
  address2: null,
  completeAddress: null,
  defaultCardId: null,
};

const Card = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;
    case "SET_ADD_CARD_MODAL":
      return {
        ...state,
        addCardModal: payload,
      };
    case "SET_DELETE_CARD_RESPONSE":
      return {
        ...state,
        deleteCardResponse: payload,
      };
    case "SET_CARD_OBJECT":
      return {
        ...state,
        cardObject: payload,
      };
    case "SET_DEFAULT_CARD_ID":
      return {
        ...state,
        defaultCardId: payload,
      };
    case "RESET_STORE":
      return (state = initialState);
    case "SET_YEAR_LIST":
      return {
        ...state,
        expYearList: payload,
      };

    case "SET_ZIP_CODE":
      return {
        ...state,
        zipCode: payload,
      };
    case "SET_STATE":
      return {
        ...state,
        state: payload,
      };
    case "SET_CITY":
      return {
        ...state,
        city: payload,
      };
    case "SET_ADDRESS1":
      return {
        ...state,
        address1: payload,
      };
    case "SET_ADDRESS2":
      return {
        ...state,
        address2: payload,
      };
    case "SET_COMPLETE_ADDRESS":
      return {
        ...state,
        completeAddress: payload,
      };
    case "SET_DEFAULT_CARD_RESPONSE":
      return {
        ...state,
        defaultCardResponse: payload,
      };

    case "SET_SELECTED_ADDRESS":
      return {
        ...state,
        selectedAddress: payload,
      };

    case "SET_ADD_CARD_RESPONSE":
      return {
        ...state,
        addCardResponse: payload,
      };

    default:
      return state;
  }
};

export default Card;
