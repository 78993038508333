import { PURGE } from "redux-persist";

var initialState = {
  googleMapPlaceResponse: null,
};

const Location = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "SET_GOOGLE_PLACES_RESPONSE":
      return {
        ...state,
        googleMapPlaceResponse: payload,
      };

    default:
      return state;
  }
};

export default Location;
