import { PURGE } from "redux-persist";

var initialState = {
  serviceAreaAvailabilityResponse: null,
};

const ServiceAreaAvailability = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_SERVICE_AREA_AVAILABILITY":
      return {
        ...state,
        serviceAreaAvailabilityResponse: payload,
      };

    default:
      return state;
  }
};

export default ServiceAreaAvailability;
