import React, { Component, Suspense, lazy } from "react";
import { Route } from "react-router";
import CryptoJS from "crypto-js";
import Layout from "./components/Layout";
import { connect } from "react-redux";
import SuspenseComponent from "./components/Suspense";
import "./custom.css";
import { setLoader } from "./store/action/Common";
import { fetchOrderDetails } from "./store/action/Order";
import { setUserLogin, postLogin } from "./store/action/Login";
import { fetchCustomerProfile } from "./store/action/Profile";
import history from "./history";

const Login = lazy(() => import("./components/Login"));
const ContactUs = lazy(() => import("./components/ContactUs"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const Cookies = lazy(() => import("./components/Cookies"));
const PromotionHistory = lazy(() => import("./components/PromotionHistory"));
const OrderSummary = lazy(() => import("./components/OrderSummary"));
const FAQ = lazy(() => import("./components/FAQ"));
const Profile = lazy(() => import("./components/Profile"));
const Order = lazy(() => import("./components/Order"));
const OrderHistory = lazy(() => import("./components/OrderHistory"));
const PasswordReset = lazy(() => import("./components/PasswordReset"));
const TermAndConditions = lazy(() => import("./components/TermAndConditions"));

class App extends Component {
  static displayName = App.name;
  // geometry
  async componentDidMount() {
    // function callback(results, status) {
    //   if (status == window.google.maps.places.PlacesServiceStatus.OK) {
    //     console.log("latitude ", results[0].geometry.location.lat());
    //     console.log("longitude ", results[0].geometry.location.lng());
    //   }
    //   console.log(results);
    // }
    // var michigan = new window.google.maps.LatLng(44.978718, -84.515887);
    // var map = new window.google.maps.Map({
    //   center: michigan,
    //   zoom: 15,
    // });
    // var request = {
    //   location: { lat: 44.978718, lng: -84.515887 },
    //   radius: "0",
    //   query: "366 West Circle Drive, East Lansing, MI 48824",
    // };
    // var services = new window.google.maps.places.PlacesService(map);
    // services.textSearch(request, callback);
    const windowLocation = window.location.href;
    let passwordPath = windowLocation.substr(windowLocation.lastIndexOf("#") + 1);
    if (localStorage.getItem("email") && localStorage.getItem("password")) {
      this.props.setLoader(true);
      if (localStorage.getItem("hashPasswordFlag")) {
        var passwordBytes = CryptoJS.AES.decrypt(localStorage.getItem("password"), process.env.REACT_APP_SECRET_PASSWORD_KEY);
     
        var decryptedPassword = JSON.parse(passwordBytes.toString(CryptoJS.enc.Utf8));

        var emailBytes = CryptoJS.AES.decrypt(localStorage.getItem("email"), process.env.REACT_APP_SECRET_EMAIL_KEY);
        var decryptedEmail = JSON.parse(emailBytes.toString(CryptoJS.enc.Utf8));

        await this.props.postLogin({
          Password: decryptedPassword,
          Username: decryptedEmail,
        });
      } else {
        await this.props.postLogin({
          Password: localStorage.getItem("password"),
          Username: localStorage.getItem("email"),
        });
      }

      if (this.props.loginResponse?.ResultMessages[0]?.HasErrors) {
        this.props.setLoader(false);
        history.push("/");
      } else {
        this.props.setUserLogin(true);
        await this.props.fetchCustomerProfile(localStorage.getItem("ID"), localStorage.getItem("Token"));
        await this.props.fetchOrderDetails(localStorage.getItem("ID"), localStorage.getItem("Token"));
        this.props.setLoader(false);
        const ddd = window.location.href;
        let path = ddd.substr(ddd.lastIndexOf("#") + 1);
        if (path === "/") path = path + "Order";
        history.push(path);
      }
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 14) == "/PasswordReset") {
      history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 10) == "/ContactUs") {
      history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 4) == "/FAQ") {
      history.push(passwordPath);
    } else if (windowLocation.substr(windowLocation.lastIndexOf("#") + 1, 19) == "/TermsAndConditions") {
      history.push(passwordPath);
    } else {
      history.push("/");
    }
  }

  render() {
    return (
      <Layout>
        <Suspense fallback={<SuspenseComponent />}>
          <Route exact path='/' component={Login} />
          <Route path='/OrderHistory' component={OrderHistory} />
          <Route path='/Order' component={Order} />
          <Route path='/Profile' component={Profile} />
          <Route path='/ContactUs' component={ContactUs} />
          <Route path='/PromotionHistory' component={PromotionHistory} />
          <Route path='/FAQ' component={FAQ} />
          <Route path='/OrderSummary' component={OrderSummary} />
          <Route path='/Cookies' component={Cookies} />
          <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
          <Route path='/TermsAndConditions' component={TermAndConditions} />
          <Route path='/PasswordReset/:pathParam1?' component={PasswordReset} />
        </Suspense>
      </Layout>
    );
  }
}

const mapDispatchToProps = {
  fetchOrderDetails,
  setUserLogin,
  postLogin,
  setLoader,
  fetchCustomerProfile,
};

const mapStateToProps = ({ Login }) => {
  let { loginResponse } = Login;
  return { loginResponse };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
