import React, { Component } from "react";
import spinner from "../imgs/loading.gif";
import "./spinner.css";

class Suspense extends Component {
  render() {
    return (
      <div
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
        }}
        className='spin_loader'
      >
        <div className='spin_img'>
          <img src={spinner} alt='spinner' className='img-fluid' />
        </div>
      </div>
    );
  }
}

export default Suspense;
