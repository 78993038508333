import React, { Component } from "react";
import "react-block-ui/style.css";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { setUserLogin } from "../store/action/Login";
import Header from "./Header";
import Topnav from "./Topnav";
import Footer from "./Footer";
import BlockUi from "react-block-ui";
// import LoadingOverlay from "react-loading-overlay";
// import BounceLoader from "react-spinners/BounceLoader";

{
  /* <LoadingOverlay
  active={isActive}
  spinner
  text='Loading your content...'
  ></LoadingOverlay> */
}

{
  /* ; */
}
// <LoadingOverlay active={this.props.isLoading} spinner={<BounceLoader color="white"/>} text='' className='myLoading'>

class Layout extends Component {
  static displayName = Layout.name;

  render() {
    const isUserLogin = this.props.isUserLogin;
    return (
      <BlockUi
        tag='div'
        blocking={this.props.isLoading}
        message='Please wait'
        keepInView='true'
        className='block-ui block-ui-anim-fade'
      >
        <div className='container-fluid'>
          {isUserLogin && (
            <>
              <div>
                <Header />
              </div>
              <div>
                <Topnav />
              </div>
            </>
          )}
          <div>{this.props.children}</div>
          {isUserLogin && <Footer />}
          <ToastContainer position='bottom-right' limit={1} transition={Flip} theme={"colored"} />
        </div>
      </BlockUi>
    );
  }
}
const mapDispatchToProps = {
  setUserLogin,
};
const mapStateToProps = ({ Login, Common }) => {
  let { isUserLogin } = Login;
  let { isLoading } = Common;
  return { isUserLogin, isLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
