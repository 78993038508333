import CryptoJS from "crypto-js";


export const setUserLogin = data => ({
  type: "SET_USER_LOGIN",
  payload: data,
});

export const setWebBanner = data => ({
  type: "SET_WEB_BANNER",
  payload: data,
});

export const setLoginResponse = data => ({
  type: "SET_LOGIN_RESPONSE",
  payload: data,
});

export const setUserEmail = data => ({
  type: "SET_USER_EMAIL",
  payload: data,
});

export const setUserPassword = data => ({
  type: "SET_USER_PASSWORD",
  payload: data,
});

export const setLoginMessage = data => ({
  type: "SET_LOGIN_MESSAGE",
  payload: data,
});

export const setAvailabilityMessage = data => ({
  type: "SET_AVAILABILITY_MESSAGE",
  payload: data,
});

export const postLogin = viewModel => {
  return async function (dispatch) {
    return await postData(`${process.env.REACT_APP_ROOT_URL}/account/PostCustomerLogin`, viewModel)
      .then(data => {
        if (data.ID != 0) {
          // dispatch(setUserLogin(true));
          let passwordEncrypted = CryptoJS.AES.encrypt(JSON.stringify(viewModel.Password), process.env.REACT_APP_SECRET_PASSWORD_KEY).toString();
          var emailEncrypted = CryptoJS.AES.encrypt(JSON.stringify(viewModel.Username), process.env.REACT_APP_SECRET_EMAIL_KEY).toString();
          console.log("userName: ", emailEncrypted);
          console.log("password: ", passwordEncrypted);

          localStorage.setItem("email", emailEncrypted);
          localStorage.setItem("password", passwordEncrypted);

          localStorage.setItem("hashPasswordFlag", true); 

          localStorage.setItem("ID", data.ID);
          localStorage.setItem("Token", data.Token);
        }
        dispatch(setLoginResponse(data));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

const postData = async (url = "", data = {}) => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify(data),
  });
  return response.json(); // parses JSON response into native JavaScript objects
};
