import { PURGE } from "redux-persist";

var initialState = {
  customerProfileResponse: null,
  defaultAddressModal: false,
  defaultAddress_2: null,
  deleteAddressModal: false,
  deleteAddress_2: null,
  editProfileAddressModal: false,
  addProfileAddressModal: false,
  addProfileCardModal: false,
  deleteProfileCardModal: false,
  deleteProfileCard: null,
  defaultProfileCard: null,
  defaultProfileCardModal: false,
  editProfileModal: false,
  postCustomerProfileResponse: null,
  profilePictureResponse: null,
  changePasswordModal: false,
  changePasswordResponse: null,
  isProfileImageIsUploading: false,
};

const Profile = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_CUSTOMER_PROFILE_RESPONSE":
      return {
        ...state,
        customerProfileResponse: payload,
      };

    case "SET_EDIT_PROFILE_ADDRESS_MODAL":
      return {
        ...state,
        editProfileAddressModal: payload,
      };

    case "SET_UPLOADING_PROFILE_IMAGE":
      return {
        ...state,
        isProfileImageIsUploading: payload,
      };

    case "SET_CHANGE_PASSWORD_MODAL":
      return {
        ...state,
        changePasswordModal: payload,
      };

    case "SET_CHANGE_PASSWORD_RESPONSE":
      return {
        ...state,
        changePasswordResponse: payload,
      };

    case "SET_ADD_PROFILE_ADDRESS_MODAL":
      return {
        ...state,
        addProfileAddressModal: payload,
      };

    case "SET_DEFAULT_ADDRESS_MODAL_2":
      return {
        ...state,
        defaultAddressModal: payload,
      };

    case "SET_DELETE_ADDRESS_MODAL_2":
      return {
        ...state,
        deleteAddressModal: payload,
      };

    case "SET_DEFAULT_ADDRESS_2":
      return {
        ...state,
        defaultAddress_2: payload,
      };

    case "SET_DELETE_ADDRESS_2":
      return {
        ...state,
        deleteAddress_2: payload,
      };

    case "SET_ADD_PROFILE_CARD_MODAL":
      return {
        ...state,
        addProfileCardModal: payload,
      };

    case "SET_DELETE_PROFILE_CARD_MODAL":
      return {
        ...state,
        deleteProfileCardModal: payload,
      };

    case "SET_DEFAULT_PROFILE_CARD_MODAL":
      return {
        ...state,
        defaultProfileCardModal: payload,
      };

    case "SET_PROFILE_PICTURE_RESPONSE":
      return {
        ...state,
        profilePictureResponse: payload,
      };

    case "SET_DELETE_PROFILE_CARD":
      return {
        ...state,
        deleteProfileCard: payload,
      };
    case "SET_DEFAULT_PROFILE_CARD":
      return {
        ...state,
        defaultProfileCard: payload,
      };

    case "SET_EDIT_PROFILE_MODAL":
      return {
        ...state,
        editProfileModal: payload,
      };

    case "SET_POST_CUSTOMER_PROFILE_RESPONSE":
      return {
        ...state,
        postCustomerProfileResponse: payload,
      };

    default:
      return state;
  }
};

export default Profile;
