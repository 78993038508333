import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoginResponse, setUserLogin } from "../store/action/Login";
import { resetStore } from "../store/action/Logout";
import history from "../history";

class Header extends Component {
  render() {
    return (
      <div className='row top_header clearfix'>
        <div className='wrapper'>
          <p>
            <i className='fa fa-envelope'></i>
            <a href='mailto:customercare@drycleandepot.com' className='color-email'>
              customercare@drycleandepot.com
            </a>
          </p>

          <div className='dropdown login_dropdown'>
            <button className='btn dropdown-toggle' type='button' data-toggle='dropdown'>
              <i className='fa fa-user' aria-hidden='true'></i> Hi, {this.props.customerProfileResponse?.Customer?.FirstName}{" "}
              {this.props.customerProfileResponse?.Customer?.LastName} <span className='caret'></span>
            </button>
            <ul className='dropdown-menu'>
              <li>
                <a
                  onClick={() => {
                    localStorage.removeItem("Token");
                    localStorage.removeItem("ID");
                    localStorage.removeItem("password");
                    localStorage.removeItem("email");
                    localStorage.removeItem('hashPasswordFlag');
                    sessionStorage.removeItem("path");
                    this.props.setUserLogin(false);
                    this.props.resetStore();
                    history.push("/");
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoginResponse,
  setUserLogin,
  resetStore,
};
const mapStateToProps = ({ Login, Profile }) => {
  let { loginResponse } = Login;
  let { customerProfileResponse } = Profile;
  return { loginResponse, customerProfileResponse };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
