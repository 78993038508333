import { PURGE } from "redux-persist";

var initialState = {
  isUserLogin: false,
  availabilityMessage: "",
  loginMessage: "",
  webBanner: true,
  loginResponse: null,
  userEmail: "",
  userPassword: "",
};

const Login = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_USER_LOGIN":
      return {
        ...state,
        isUserLogin: payload,
      };
    case "SET_WEB_BANNER":
      return {
        ...state,
        webBanner: payload,
      };

    case "SET_LOGIN_RESPONSE":
      return {
        ...state,
        loginResponse: payload,
      };

    case "SET_USER_EMAIL":
      return {
        ...state,
        userEmail: payload,
      };
    case "SET_USER_PASSWORD":
      return {
        ...state,
        userPassword: payload,
      };
    case "SET_LOGIN_MESSAGE":
      return {
        ...state,
        loginMessage: payload,
      };
    case "SET_AVAILABILITY_MESSAGE":
      return {
        ...state,
        availabilityMessage: payload,
      };

    default:
      return state;
  }
};

export default Login;
