import { PURGE } from "redux-persist";

var initialState = {
  promotionsResponse: null,
  referAFriendModal: false,
  referralResponse: null,
  referralEmailResponse: null,
  promotion_web_url_response: null,
};

const Promotions = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case "RESET_STORE":
      return (state = initialState);

    case "SET_PROMOTION_HISTORY":
      return {
        ...state,
        promotionsResponse: payload,
      };

    case "SET_REFER_A_FRIEND_MODAL":
      return {
        ...state,
        referAFriendModal: payload,
      };

    case "SET_POST_PROMOTION_RESPONSE":
      return {
        ...state,
        referralResponse: payload,
      };

    case "SET_POST_PROMOTION_EMAIL_RESPONSE":
      return {
        ...state,
        referralEmailResponse: payload,
      };

    case "SET_PROMOTION_WEB_URL_RESPONSE":
      return {
        ...state,
        promotion_web_url_response: payload,
      };

    default:
      return state;
  }
};

export default Promotions;
