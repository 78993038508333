export const setCustomerProfileResponse = data => ({
  type: "SET_CUSTOMER_PROFILE_RESPONSE",
  payload: data,
});

export const setProfilePictureResponse = data => ({
  type: "SET_PROFILE_PICTURE_RESPONSE",
  payload: data,
});

export const setDefaultAddressModal_2 = data => ({
  type: "SET_DEFAULT_ADDRESS_MODAL_2",
  payload: data,
});

export const setEditProfileAddressModal = data => ({
  type: "SET_EDIT_PROFILE_ADDRESS_MODAL",
  payload: data,
});

export const setAddProfileAddressModal = data => ({
  type: "SET_ADD_PROFILE_ADDRESS_MODAL",
  payload: data,
});

export const setDeleteAddressModal_2 = data => ({
  type: "SET_DELETE_ADDRESS_MODAL_2",
  payload: data,
});

export const setDefaultAddress_2 = data => ({
  type: "SET_DEFAULT_ADDRESS_2",
  payload: data,
});

export const setDeleteAddress_2 = data => ({
  type: "SET_DELETE_ADDRESS_2",
  payload: data,
});

export const setAddProfileCardModal = data => ({
  type: "SET_ADD_PROFILE_CARD_MODAL",
  payload: data,
});

export const setDeleteProfileCardModal = data => ({
  type: "SET_DELETE_PROFILE_CARD_MODAL",
  payload: data,
});

export const setDefaultProfileCardModal = data => ({
  type: "SET_DEFAULT_PROFILE_CARD_MODAL",
  payload: data,
});

export const setDeleteProfileCard = data => ({
  type: "SET_DELETE_PROFILE_CARD",
  payload: data,
});

export const setDefaultProfileCard = data => ({
  type: "SET_DEFAULT_PROFILE_CARD",
  payload: data,
});

export const setEditProfileModal = data => ({
  type: "SET_EDIT_PROFILE_MODAL",
  payload: data,
});

export const setPostCustomerProfileResponse = data => ({
  type: "SET_POST_CUSTOMER_PROFILE_RESPONSE",
  payload: data,
});

export const setChangePasswordModal = data => ({
  type: "SET_CHANGE_PASSWORD_MODAL",
  payload: data,
});

export const setChangePasswordResponse = data => ({
  type: "SET_CHANGE_PASSWORD_RESPONSE",
  payload: data,
});

export const setUploadingProfileImage = data => ({
  type: "SET_UPLOADING_PROFILE_IMAGE",
  payload: data,
});
// const rootURL = "https://pndapi.drycleandepotdelivers.com/";

export const fetchCustomerProfile = (customerId, token) => {
  return async function (dispatch) {
    return await fetch(`${process.env.REACT_APP_ROOT_URL}/Customer?customerId=${customerId}`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          dispatch(setCustomerProfileResponse(result));
        },
        error => {
          console.error(error);
        }
      );
  };
};

export const postProfilePicture = (file, token) => {
  return async function (dispatch) {
    return await postPicture(`${process.env.REACT_APP_ROOT_URL}/Customer/UploadProfileImage`, file, token)
      .then(data => {
        dispatch(setProfilePictureResponse(data));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const postCustomerProfile = (customer, token) => {
  return async function (dispatch) {
    return await postData(`${process.env.REACT_APP_ROOT_URL}/Customer/PostCustomerProfile`, customer, token)
      .then(data => {
        dispatch(setPostCustomerProfileResponse(data));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const postChangePassword = (viewModel, token) => {
  return async function (dispatch) {
    return await postData(`${process.env.REACT_APP_ROOT_URL}/account/PostChangePassword`, viewModel, token)
      .then(data => {
        dispatch(setChangePasswordResponse(data));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

const postData = async (url = "", data = {}, token) => {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const postPicture = async (url = "", data, token) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: data,
  });
};
